/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './joinStyle';
import React from 'react';
import env from '../../env';
import utils from '../../utils';
import { SiteLink } from '../../components';

import { usePost } from '../../components';
import { useForm } from '../../form';
import { useStep } from '../warranty/useStep';
import { Button, ErrorMessage } from '../../components';
import { getFormDataStep1, getFormDataStep2, getFormDataStep3, getFormDataStep5 } from './joinFormData';
//import { useBV } from '../bazzarVoice/useBV';


export function Join() {

  //const post = usePost();
  const { steps, currentStep, lastStep, moveTo, moveNext, markAsFilled, canGo } = useStep([
    { id: 'name' },
    { id: 'contact' },
    { id: 'dob' },
    //{ id: 'hairType' },
    { id: 'interests' },
    { id: 'thanks' },
  ])
  const model1 = getFormDataStep1({})
  const form1 = useForm(model1, {});
  const model2 = getFormDataStep2({})
  const form2 = useForm(model2, {});
  const model3 = getFormDataStep3({})
  const form3 = useForm(model3, {});
  // const model4 = getFormDataStep4({})
  // const form4 = useForm(model4, {});
  const model5 = getFormDataStep5({})
  const form5 = useForm(model5, {});

  const navEl = React.useRef(null);

  const stepStyle = (index) => {
    const locStyle = index === currentStep ? style.stepCurrent : (
      index === currentStep - 1 ? style.stepPrev : (
        index === currentStep + 1 ? style.stepNext : style.step))
    const movingStyle = index === lastStep ? style.stepLast : null;
    return [locStyle, movingStyle];
  }


  function Nav() {
    if (currentStep === steps.length - 1) return null;
    return <div css={style.navs}>
      {steps.filter(x => x.id !== 'thanks').map((step, index) =>
        <div key={step.id}
          css={[style.nav,
          step.filled && style.navFilled,
          index === currentStep ? style.navCurrent : null,
          canGo(index) && style.navClickable
          ]}
          onClick={() => moveTo(index)}
        ></div>)}
    </div>
  }

  const next = () => {
    markAsFilled(currentStep);
    moveNext();

    if (!utils.ui.isElementInViewport(navEl.current)) {
      utils.ui.scrollTo(navEl.current)
    }
  }

  const onSubmit = () => {
    markAsFilled(currentStep);
    moveNext();

    if (!utils.ui.isElementInViewport(navEl.current)) {
      utils.ui.scrollTo(navEl.current)
    }
  }

  const getSelection = () => {
    const value = {...form1.getValue(), ...form2.getValue(), ...form3.getValue(), ...form5.getValue()}
    //console.log(value)
    return { productName: '9000 CORDLESS STRAIGHTENER', value: value };
  }

  return <div css={style.joinForm}>
    <div css={style.stepsWrapper}>
      <div ref={navEl}><Nav /></div>
      <div css={style.steps} style={{ transform: `translateX(${-100 * currentStep}%)` }}>
        {steps.map((step, index) => {
          let stepView = null
          switch (step.id) {
            case 'name': stepView = <Step1 model={model1} form={form1} moveNext={next} getSelection={getSelection} />; break;
            case 'contact': stepView = <Step2 model={model2} form={form2} moveNext={next} getSelection={getSelection} />; break;
            case 'dob': stepView = <Step3 model={model3} form={form3} moveNext={next} getSelection={getSelection} />; break;
            // case 'hairType': stepView = <Step4 model={model4} form={form4} moveNext={next} getSelection={getSelection} />; break;
            case 'interests': stepView = <Step5 model={model5} form={form5} onSubmit={onSubmit} getSelection={getSelection} />; break;
            case 'thanks': stepView = <Step6 getSelection={getSelection} />; break;
            default: break;
          }
          return (
            <div css={stepStyle(index)} key={step.id}>
              {stepView}
            </div>
          )
        })}
      </div>
    </div>
  </div>;
}


function Step1({ model, form, moveNext, getSelection }) {
  const render = (name) => form.renderControl(name, null);

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    })
  }

  return <div>
    <h4>Please tell us a bit about yourself</h4>
    <form>
      <div className="row">
        <div className="col-12">
          {render('firstName')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {render('lastName')}
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12">
          {render('gender')}
        </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <div className="form__actions">
            <Button onClick={next} status={form.status}>Next</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          </div>
        </div>
      </div>
    </form>
  </div>
}

function Step2({ model, form, moveNext, getSelection }) {
  const render = (name) => form.renderControl(name, null);

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    })
  }

  return <div>
    <h4>How can we contact you?</h4>
    <form>
      <div className="row">
        <div className="col-12">
          {render('email')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {render('mobile')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form__actions">
            <Button onClick={next} status={form.status}>Next</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          </div>
        </div>
      </div>
    </form>
  </div>
}

function Step3({ model, form, moveNext, getSelection }) {
  const render = (name) => form.renderControl(name, null);

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    })
  }

  return <div>
    <h4>Tell us more about you.</h4>
    <p>Awesome!</p>
    <p>Let us know a bit more about yourself so that we can personalise what we send.</p>
    <form>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('dob')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {render('postcode')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form__actions">
            <Button onClick={next} status={form.status}>Next</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          </div>
        </div>
      </div>
    </form>
  </div>
}

// function Step4({ model, form, moveNext, getSelection }) {
//   const render = (name) => form.renderControl(name, null);

//   const next = (e) => {
//     form.validateForm(e, () => {
//       moveNext();
//     })
//   }

//   return <div>
//     <p>How would you describe your hair?</p>
//     <p><i>Choose everything that applies.</i></p>
//     <form>
//       <div className="row">
//         <div className="col-12">
//           {render('hairTypes')}
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-12">
//           <div className="form__actions">
//             <Button onClick={next} status={form.status}>Next</Button>
//             <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
//           </div>
//         </div>
//       </div>
//     </form>
//   </div>
// }

function Step5({ model, form, onSubmit, getSelection }) {
  const render = (name) => form.renderControl(name, null);

  const post = usePost();

  // const bv = useBV({
  //   showReview: null
  // });

  const next = (e) => {
    form.validateForm(e, () => {
      const selection = getSelection();
      post.send(env.apiBase + "/api/join/register", selection.value);
    })
  }

  if (post.status === 'done') {
    // // send BV pixel
    // const value = getSelection().value;
    // //const modelNumber = value.modelNumber;
    // const nickname = value.firstName + ' ' + value.lastName;
    // const email = value.email;
    // const locale = env.bvLocale;

    // bv.sendEvent('JoinUs', 'Registration', nickname, { "email": email, "locale": locale, "nickname": nickname})


    post.reset();
    onSubmit();
  }

  return <div>
          
          <form>
            <div className="row">
              <div className='col-12 col-md-7'>
                <h4>What are you interested in hearing about?</h4>
                <p><i>Choose everything that applies.</i></p>
                <div>
                {render('interests')}
                </div>
                  <p>By clicking 'Submit' you accept the terms of our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                  <div className="form__actions">
                    <Button onClick={next} status={form.status}>Submit</Button>
                    <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
                    <ErrorMessage errors={post.errors} />
                  </div>
              </div>
              
              <div className='col-12 col-md-5'>
                <img src="/assets/lady-with-love.jpg" className='mt-1r' alt="young lady with love" />
              </div>
            </div>
          </form>
        </div>
}

function Step6({ getSelection }) {
  //const selection = getSelection();
  return <div>
    <p css={{marginTop: '2rem'}}>CONGRATULATIONS! You’ve successfully registered to access VS VIPs. </p>
    <SocialLinks/>
    <p>
If you need help from our Customer Service Team simply <SiteLink to="/contact">Ask Us</SiteLink></p>
  </div>
}

function SocialLinks() {
  const instaLink = env.siteCode === 'vssassoon' ?
    'https://instagram.com/vs_sassoon' : 'https://www.instagram.com/vsformen_anz/'
  const youtubeLink = env.siteCode === 'vssassoon' ?
    'https://www.youtube.com/user/vssassoon' : 'https://www.youtube.com/playlist?list=PLxG2Cg96L0isvQfXmM-RemWmJ94h_oKrV'
  return <React.Fragment>
    <p>
      We'll be sure to keep you in-the-loop on all that's happening in the VS world, but don't forget to check us out on
<a href={instaLink} target="_blank" rel="noopener noreferrer"> 'the gram' <img css={{ width: '2em', marginRight: '0.5em' }} alt="" data-widget="image" src="/assets/social/instagram.png" /></a>

 and <a href={youtubeLink} target="_blank" rel="noopener noreferrer">view our latest videos <img css={{ width: '2em' }} alt="" src="/assets/social/youtube.png" /></a> </p>
  </React.Fragment>
}